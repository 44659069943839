
import { defineComponent, ref, toRefs } from 'vue';
import useDGRCAccounting from '@/hooks/payment/DgrcAccounting/useDGRCAccounting';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons-vue';
import TableWrapper from '@/views/Payment/components/TableWrapper.vue';
import uploadFile from './uploadFile.vue';
export default defineComponent({
    name: 'App',
    props: {},
    setup() {
        const {
            search,
            query,
            reset,
            column,
            data,
            tableSelection,
            searchRoundList,
            roundList,
            download,
            pagination,
            pageChange,
            sizeChange,
        } = useDGRCAccounting();
        // 上传相关
        const uploadVisible = ref<boolean>(false);
        const curRoleType = ref();
        const upload = (type: string) => {
            uploadVisible.value = true;
            curRoleType.value = type;
        };
        const init = () => {
            searchRoundList().then(() => {
                search();
            });
        };
        init();
        const handleTableChange = () => {
            search();
        };
        return {
            query,
            search,
            reset,
            column,
            data,
            tableSelection,
            roundList,
            download,
            upload,
            curRoleType,
            uploadVisible,
            isPagination: true,
            ...toRefs(pagination),
            pageChange,
            sizeChange,
            handleTableChange,
        };
    },
    components: {
        UploadOutlined,
        DownloadOutlined,
        TableWrapper,
        uploadFile,
    },
});
